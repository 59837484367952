<template>
  <v-container grid-list-xl fluid>
    <listarClientes
      v-bind:titulo="'Consultar Ordenes de Trabajo'"
      v-bind:busmodulo="{ busmodulo }"
    ></listarClientes>
    <consultarodt></consultarodt>
  </v-container>
</template>

<script>
import clienteService from "@/services/ClienteServices";
import listarClientes from "@/components/cliente/ListarClientes.vue";
import consultarodt from "@/components/pedidos/consultar.vue";
export default {
  name: "Pedidos",
  components: { listarClientes, consultarodt },
  busmodulo: "Pedidos",
  created() {
    this.cliente = clienteService.current();
    this.$bus.$on("SelectorClienteSetCliente_"+this.busmodulo, () => {
      this.cliente = clienteService.current();
    });
  },
};
</script>